<template>
  <!-- edit profile dialog -->

  <v-card
    flat
    class="user-edit-info pa-sm-2 pa-3"
  >
    <!-- <v-card-title class="justify-center text-h5">
      Update your information
    </v-card-title> -->

    <v-card-text class="">
      <v-form
        ref="medHistoryForm"
        class="multi-col-validation"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <p class="tw-text-gray-600 tw-text-base">
              Do you have any allergies?
            </p>
            <v-radio-group
              v-model="userDataLocal.hasAllergy"
              :rules="radioRules"
            >
              <v-radio
                v-for="(option) in boolRadioOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></v-radio>
            </v-radio-group>
            <div v-if="userDataLocal.hasAllergy">
              <p class="tw-text-gray-600 tw-text-base">
                Please tell us about your allergies
              </p>
              <v-textarea
                v-model="userDataLocal.allergyDescription"
                label="Please share what are you allergic to and what happens?"
                rows="4"
                outlined
                background-color="fieldBg"
              ></v-textarea>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <p class="tw-text-gray-600 tw-text-base">
              Are you currently taking any medications?
            </p>
            <v-radio-group
              v-model="userDataLocal.takesMeds"
              :rules="radioRules"
            >
              <v-radio
                v-for="(option) in boolRadioOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></v-radio>
            </v-radio-group>
            <div v-if="userDataLocal.takesMeds">
              <p class="tw-text-gray-600 tw-text-base">
                Please tell us what medications you take
              </p>
              <v-textarea
                v-model="userDataLocal.medicationList"
                label="Please share medication names, strengths, and dosage"
                rows="4"
                outlined
                background-color="fieldBg"
              ></v-textarea>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="d-flex justify-center mt-3"
          >
            <div>
              <v-btn
                vue-tour-target="previousButton"
                outlined
                class="me-2"
                @click="previous"
              >
                Previous
              </v-btn>
            </div>
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :outlined="nextState"
              @click.prevent="next"
            >
              Next
              <v-icon
                v-if="nextState"
                dark
                right
                color="success"
              >
                {{ icons.mdiCheckboxMarkedCircleOutline }}
              </v-icon>
            </v-btn>
            <!-- <v-btn
              :outlined="!nextState"
              color="success"
              class="me-3"
              @click.prevent="next"
            >
              Next
              <v-icon
                dark
                right
              >
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn> -->
            <!-- <v-btn
              outlined
              color="secondary"
              @click.prevent="savedState"
            >
              Discard
            </v-btn> -->
          </v-col>
          <v-col
            v-if="saveSuccess || invalidForm"
            cols="12"
            sm="12"
          >
            <v-alert
              v-if="saveSuccess"
              type="success"
            >
              Thank you! Your profile has been <strong>updated</strong>
            </v-alert>
            <v-alert
              v-if="invalidForm"
              color="error"
              dark
              class="mb-0"
            >
              Please fill required fields!
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiChevronRight, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
    required,
    emailValidator,
    integerValidator
} from '@core/utils/validation'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import { splitFullName, formatTelNum } from '@/util/functions/index.js'

export default {
    name: 'MedicalHistory',
    props: {
        isBioDialogOpen: {
            type: Boolean,
            required: true,

            // default: true
        },

    // userData: {
    //     type: Object,
    //     required: true,
    // },
    },
    data() {
        return {
            nextState: false,
            boolRadioOptions: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
            ],
            refreshKey: 0,
            visitId: null,
            dob: null,
            saveSuccess: false,
            invalidForm: false,

            address_components: [],
            autoAddress: '',
            userDataLocal: {
                hasAllergy: null,
                takesMeds: null,
                allergyDescription: '',
                medicationList: '',
            },
            icons: {
                mdiChevronRight,
                mdiCheckboxMarkedCircleOutline
            },
        }
    },
    setup() {
        const visitStore = useVisitStore()
        const timeStamp = new Date(Date.now()).toISOString()
        const medHistoryForm = ref(null)
        const radioRules = [value => ((value === 1 || value === 0) ? true : 'Please select an option')]

        const validate = () => {
            medHistoryForm.value.validate()
        }

        return {
            emailValidator,
            integerValidator,
            medHistoryForm,
            validate,
            required,
            visitStore,
            timeStamp,

            radioRules
        }
    },
    computed: {
        patient() {
            return splitFullName(this.visitStore.patientData.name)
        },
        patientData() {
            return this.visitStore.patientData
        },
    },
    watch: {
        isBioDialogOpen(val) {
            if (val) {
            }
        },
        userDataLocal: {
            handler() {
                if (this.userDataLocal.hasAllergy === 0) {
                    this.userDataLocal.allergyDescription = ''
                }
                if (this.userDataLocal.takesMeds === 0) {
                    this.userDataLocal.medicationList = ''
                }
            },
            deep: true,
        },

    },
    mounted() {
        this.id = this.visitStore.visitId
        this.dob = this.visitStore.dob
        this.savedState()
    },
    methods: {
        next() {
            const validForm = this.$refs.medHistoryForm.validate()
            if (validForm) {
                this.onSubmit()
                this.$emit('next', 3)
            } else if (!validForm) {
                this.invalidForm = true
                setTimeout(() => {
                    this.invalidForm = false
                }, 2500)
            }
        },
        previous() {
            this.$emit('previous', 1)
        },
        closeDialog() {
        },
        async onSubmit() {
            const data = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                id: this.visitStore.patientData.id,
                hasAllergy: this.userDataLocal.hasAllergy,
                allergyDescription: this.userDataLocal.allergyDescription,
                medicationList: this.userDataLocal.medicationList,
                takesMeds: this.userDataLocal.takesMeds,
            }
            const validForm = this.$refs.medHistoryForm.validate()

            if (validForm) {
                const response = await visitService.updatePatientProfile(data)
                if (response.status === 'success') {
                    this.saveSuccess = true
                    setTimeout(() => {
                        this.saveSuccess = false
                    }, 2500)
                }

                await this.visitStore.setVisit({ id: this.id, dob: this.dob })
                this.nextState = true
            } else if (!validForm) {
                this.invalidForm = true
                setTimeout(() => {
                    this.invalidForm = false
                }, 2500)
            }
        },
        savedState() {
            this.userDataLocal = {
                hasAllergy: Object.prototype.hasOwnProperty.call(this.patientData, 'hasAllergy') ? this.patientData.hasAllergy : null,
                takesMeds: Object.prototype.hasOwnProperty.call(this.patientData, 'takesMeds') ? this.patientData.takesMeds : null,
                allergyDescription: Object.prototype.hasOwnProperty.call(this.patientData, 'allergyDescription') ? this.patientData.allergyDescription : '',
                medicationList: Object.prototype.hasOwnProperty.call(this.patientData, 'medicationList') ? this.patientData.medicationList : '',
            }
        }
    },
}
</script>
