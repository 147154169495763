<template>
  <div>
    <v-card
      flat
      class="user-edit-info "
    >
      <v-card-text class="mt-5">
        <v-form
          ref="imageUploadForm"
          class="multi-col-validation"
        >
          <v-row>
            <!-- <v-col
              cols="12"
              sm="12"
            >
              <p class="tw-text-gray-600 tw-text-base">
                Do you have coverage by Ontario Drug Beneift (ODB)
              </p>
              <v-radio-group
                v-model="userDataLocal.hasAllergy"
                :rules="radioRules"
              >
                <v-radio
                  v-for="(option) in boolRadioOptions"
                  :key="option.value"
                  :label="option.text"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
            </v-col> -->
            <v-col
              cols="12"
              sm="12"
            >
              <p class="tw-text-gray-600 tw-text-base">
                Do you have private insurance coverage?
              </p>
              <v-radio-group
                v-model="insuranceStatus"
                :rules="radioRules"
              >
                <v-radio
                  v-for="(option) in boolRadioOptions"
                  :key="option.value"
                  :label="option.text"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
              <!-- <v-alert
                v-if="invalidForm"
                color="error"
                dark
                class="mb-0"
              >
                {{ errorMessage }}
              </v-alert> -->
            </v-col>
            <div v-if="insuranceStatus === 1">
              <v-col

                cols="12"
                sm="12"
              >
                <p class="tw-text-gray-600 tw-text-base">
                  Please upload images of your insurance card(s) or documents.
                </p>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-file-input
                  ref="file"
                  v-model="images"
                  color="success"
                  :loading="loading"
                  multiple
                  placeholder="Select your files"
                  :prepend-icon="icons.mdiCameraOutline"
                  hide-details
                  outlined
                  :show-size="1000"
                  dense
                  counter
                  @change="submitFile()"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="success"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ images.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>

              <!-- <v-col
                class="d-flex justify-center"
                cols="12"
              >
                <v-btn
                  color="primary"
                  @click="submitFile"
                >
                  Upload!
                </v-btn>
              </v-col> -->

              <v-col
                cols="12"
                sm="12"
              >
                <v-alert
                  v-if="saveSuccess"
                  type="success"
                >
                  <div v-html="fileUploadMessage"></div>
                </v-alert>
                <v-alert
                  v-if="invalidForm"
                  color="error"
                  dark
                  class="mb-0"
                >
                  {{ errorMessage }}
                </v-alert>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-switch
                  v-model="showTable"
                  :label="`${switchArray[showTable]}`"
                  true-value="true"
                  false-value="false"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col
                v-if="showTable === 'true'"
                cols="12"
                sm="12"
              >
                <p>Current Uploads on file:</p>
                <v-data-table
                  :items="visitStore.patientData.upload"
                  :headers="uploadHeaders"
                  sort-by="body.timeCreated"
                  sort-desc
                >
                  <template
                    v-for="header in uploadHeaders.filter((header) =>
                      header.hasOwnProperty('formatter')
                    )"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>

                  <template #[`item.dlPDF`]="{ item }">
                    <v-icon
                      color="primary"
                      medium
                      class="me-3"
                      @click="downloadPdf(item)"
                    >
                      {{ icons.mdiCloudDownload }}
                    </v-icon>
                  </template>
                  <template #[`item.thumbnailBase64`]="{ header, value }">
                    <v-icon
                      v-if="value === ''"

                      medium
                      class="me-3"
                    >
                      {{ icons.mdiFilePdfBox }}
                    </v-icon>

                    <v-img
                      contain
                      max-height="48"
                      max-width="96"
                      :src="header.formatter(value)"
                    ></v-img>
                  </template>
                  <template #[`item.delete`]="{ item }">
                    <v-icon
                      color="error"
                      medium
                      class="me-3"
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </div>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <div>
                <v-btn
                  vue-tour-target="previousButton"
                  outlined
                  class="me-2"
                  @click="previous"
                >
                  Previous
                </v-btn>
              </div>
              <!-- <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :outlined="nextState"
                @click.prevent="closeDialog"
              >
                Close
                <v-icon
                  v-if="nextState"
                  dark
                  right
                  color="success"
                >
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn> -->
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="next"
              >
                Next
              </v-btn>
            </v-col>
            <!-- <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="submitFile"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="closeDialog"
              >
                Discard
              </v-btn>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
    mdiCameraOutline, mdiCloudDownload, mdiDeleteOutline, mdiFilePdfBox, mdiCheckboxMarkedCircleOutline
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
    required
} from '@core/utils/validation'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import {
    splitFullName, momentTimeZone, fileDownload, getFileExtension
} from '@/util/functions/index.js'

export default {
    props: {
        isBioDialogOpen: {
            type: Boolean,
            required: true,

            // default: true
        },

    },
    data() {
        return {
            showTable: 'false',
            switchArray: {
                true: 'Show Uploads',
                false: 'Hide Uploads'
            },
            boolRadioOptions: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
            ],
            images: null,
            insuranceStatus: null,
            saveSuccess: false,
            invalidForm: false,
            errorMessage: 'Please fill required fields!',
            loading: false,
            dialog: false,
            dialogDelete: false,
            Deleteconfirm: false,
            editedIndex: -1,
            editedItem: {},
            defaultItem: {},

            // autoAddress: this.visitStore.patientData.address,
            address_components: [],
            autoAddress: '',
            userDataLocal: {
                firstName: '',
                lastName: '',
                email: '',
                taxId: '',
                contact: '',
                language: '',
                country: '',
                status: '',
            },
            icons: {
                mdiCameraOutline,
                mdiCloudDownload,
                mdiDeleteOutline,
                mdiFilePdfBox,
                mdiCheckboxMarkedCircleOutline,
            },
            uploadHeaders: [
                {
                    text: 'Date',
                    align: 'left',
                    value: 'body.timeCreated',
                    formatter: x => (x ? momentTimeZone(x, 'YYYY-MM-DD hh:mm') : null),
                },

                // {
                //     text: 'Size',
                //     align: 'left',
                //     value: 'body.size',
                // },
                {
                    text: 'Type',
                    align: 'left',
                    value: 'formType',
                },
                {
                    text: 'File Type',
                    align: 'left',
                    value: 'body.name',
                    formatter: x => (x ? getFileExtension(x) : null),
                },

                // {
                //     text: 'Download',
                //     align: 'center',
                //     value: 'dlPDF',
                // },
                {
                    text: 'Thumbnail',
                    align: 'left',
                    value: 'thumbnailBase64',
                    formatter: x => (x ? `data:image/png;base64,${x}` : null),
                },
                {
                    text: 'Delete',
                    align: 'center',
                    value: 'delete',
                },

            ],
        }
    },
    setup() {
        const visitStore = useVisitStore()
        const imageUploadForm = ref(null)
        const radioRules = [value => ((value === 1 || value === 0) ? true : 'Please select an option')]
        const switch2 = ref('Hide Uploads')
        const validate = () => {
            imageUploadForm.value.validate()
        }

        return {
            switch2,
            visitStore,
            validate,
            imageUploadForm,
            required,
            radioRules
        }
    },
    computed: {
        fileUploadMessage() {
            return this.images?.length > 1 ? 'Thank you! Your files have been successfully <strong>uploaded</strong>' : 'Thank you! Your file have been successfully <strong>uploaded</strong>'
        },

        // patient() {
        //     const firstName = splitFullName(this.visitStore.patientData.name)

        //     return firstName
        // },
        patientData() {
            return this.visitStore.patientData
        },
    },
    watch: {
        dialog(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.close()
        },
        dialogDelete(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.closeDelete()
        },

        Deleteconfirm(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.closeDeleteConfirm()
        },
    },
    mounted() {
        this.userDataLocal = this.visitStore.patientData
        this.insuranceStatus = this.visitStore.patientData.insuranceStatus

        // if (this.userDataLocal.insuranceStatus) { this.insuranceStatus = this.userDataLocal.insuranceStatus }
        // console.log()
    },

    methods: {
        next() {
            const validForm = this.$refs.imageUploadForm.validate()
            if (validForm) {
                this.onSubmit()
                this.$emit('next', 4)
            } else if (!validForm) {
                this.invalidForm = true
                setTimeout(() => {
                    this.invalidForm = false
                }, 2500)
            }
        },
        previous() {
            this.$emit('previous', 2)
        },
        async downloadPdf(pdfRef) {
            const ext = getFileExtension(pdfRef.body.name)
            try {
                const data = {
                    fileName: pdfRef.body.name,
                    patientId: this.visitStore.patientData.id,
                    dateofBirth: this.visitStore.patientData.dateofBirth,
                }
                const response = await visitService.downloadPtFile(data)
                const buffer = Buffer.from(response[0].data, 'base64')
                fileDownload(buffer, pdfRef.body.name, 'application/octet-stream', ext)
            } catch (error) {
                console.log(error)
            }
        },
        async submitFile() {
            this.loading = true
            const validForm = this.images?.length > 0
            const response = []
            if (validForm) {
            // is this.images an array?
                if (this.images instanceof Array) {
                // if yes, then loop through the array and upload each image
                    for (let i = 0; i < this.images.length; i++) {
                        const image = this.images[i]
                        const data = {
                            formType: 'insuranceInfo',
                            patientId: this.visitStore.patientData.id,
                            dateofBirth: this.visitStore.patientData.dateofBirth,
                            file: image,
                        }
                        const resp = await visitService.updatePtUploadRecord(data)
                        response.push(resp)
                    }
                    if (response[0].body.id) {
                        this.loading = false
                        this.saveSuccess = true
                        setTimeout(() => {
                            this.saveSuccess = false
                        }, 5000)
                    } else if (response.status === 'invalid') {
                        this.loading = false
                        this.errorMessage = response.invalid
                        this.invalidForm = true
                        setTimeout(() => {
                            this.invalidForm = false
                        }, 5000)
                    }
                }

                await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.dob })
            } else if (!validForm) {
                this.loading = false
                this.invalidForm = true
                setTimeout(() => {
                    this.invalidForm = false
                }, 5000)
            }
        },
        uploadFile(event) {
            this.submitFile()
        },
        closeDialog() {
            this.$emit('closeDialog', false)

            this.$emit('update:is-bio-dialog-open', false)
        },

        async onSubmit() {
            const data = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                id: this.visitStore.patientData.id,
                insuranceStatus: this.insuranceStatus,
            }

            if (this.insuranceStatus === 0 || this.insuranceStatus === 1) {
                const response = await visitService.updatePatientProfile(data)
                if (response.status === 'success') {
                    this.saveSuccess = true
                    setTimeout(() => {
                        this.saveSuccess = false
                    }, 2500)
                }

                await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: data.dateofBirth })
            } else if (this.insuranceStatus !== 0 || this.insuranceStatus !== 1) {
                console.log('invalid form')
                this.invalidForm = true
                setTimeout(() => {
                    this.invalidForm = false
                }, 2500)
            }

            // this.$emit('closeDialog', false)

            // this.$emit('update:is-bio-dialog-open', false)
        },
        deleteItem(item) {
            this.editedIndex = this.visitStore.patientData.upload.indexOf(item)
            this.editedItem = {
                ...item,
            }
            this.dialogDelete = true
        },
        async deleteItemConfirm() {
            // FIXME: this.stateID should be a local variable
            // this.stateID = this.inProgressItems[this.editedIndex]._id
            // const token = await this.$auth.getTokenSilently()
            // stateService.deleteStateById(this.stateID, token)
            // this.inProgressItems.splice(this.editedIndex, 1)
            try {
                const data = {
                    fileName: this.editedItem.body.name,
                    patientId: this.visitStore.patientData.id,
                    dateofBirth: this.visitStore.patientData.dateofBirth,
                }
                const response = await visitService.deletePtFile(data)
            } catch (error) {
                console.log(error)
            }

            await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.dob })
            this.closeDelete()
            this.Deleteconfirm = true
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem,
                }
                this.editedIndex = -1
            })
        },
        closeDeleteConfirm() {
            this.Deleteconfirm = false
        },
    },
}
</script>
