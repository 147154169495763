<template>
  <!-- edit profile dialog -->
  <v-card
    flat
  >
    <!-- <v-card-title>
      {{ userDataLocal.firstName }} {{ userDataLocal.lastName }}
    </v-card-title> -->

    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation"
      >
        <v-row>
          <!-- <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="userDataLocal.firstName"
              disabled
              outlined
              dense
              label="First Name"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="userDataLocal.lastName"
              outlined
              disabled
              dense
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="userDataLocal.dateofBirth"
              outlined
              dense
              disabled
              label="Date of Birth"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="userDataLocal.healthCardNumber"
              outlined
              disabled
              dense
              label="Health Card Number"
            ></v-text-field>
          </v-col> -->
          <!-- <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="userDataLocal.phoneNumber"
              disabled
              outlined
              dense
              label="Phone Number"
              :rules="[required]"
              background-color="fieldBg"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="userDataLocal.email"
              disabled
              outlined
              dense
              label="Email"
              :rules="[required, emailValidator]"
              background-color="fieldBg"
            ></v-text-field>
          </v-col> -->
          <v-col
            cols="12"
            sm="12"
          >
            <p class="tw-text-lg tw-text-gray-600">
              Please fill your shipping address below:
            </p>
            <v-text-field
              id="autocompleteNew"
              v-model="userDataLocal.addressAuto"
              autofocus
              background-color="fieldBg"
              outlined
              dense
              label=""
              hint="Enter your address"
              persistent-hint
              autocomplete="nope"
              clearable
            ></v-text-field>
          </v-col>
          <!-- <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="userDataLocal.address"
              outlined
              dense
              disabled
              label=""
              clearable
            ></v-text-field>
          </v-col> -->

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.unit"
              outlined
              dense
              label="Unit #"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.street_number"
              outlined
              dense
              label="Street #"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.route"
              outlined
              dense
              label="Street Name"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.locality"
              outlined
              dense
              label="City"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.province"
              outlined
              dense
              label="Province"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.postal_code"
              outlined
              dense
              label="Postal Code"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :key="refreshKey"
              v-model="userDataLocal.country"
              outlined
              dense
              disabled
              label="Country"
              @change="generatedFormattedAddress"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :outlined="nextState"
              @click.prevent="onSubmit"
            >
              Next
              <v-icon
                v-if="nextState"
                dark
                right
                color="success"
              >
                {{ icons.mdiCheckboxMarkedCircleOutline }}
              </v-icon>
            </v-btn>
            <!-- v-if="nextState" -->
            <!-- <v-btn
              :outlined="!nextState"
              color="success"
              class="me-3"
              @click.prevent="next"
            >
              Next
              <v-icon
                dark
                right
              >
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn> -->
            <!-- <v-btn
              outlined
              color="secondary"
              @click.prevent="savedState"
            >
              Close
            </v-btn> -->
          </v-col>
          <v-col
            v-if="saveSuccess || invalidForm"
            cols="12"
            sm="12"
          >
            <v-alert
              v-if="saveSuccess"
              type="success"
            >
              Thank you! Your address has been <strong>updated</strong>
            </v-alert>
            <v-alert
              v-if="invalidForm"
              color="error"
              dark
              class="mb-0"
            >
              Please fill required fields!
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline, mdiChevronRight } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Loader } from '@googlemaps/js-api-loader'
import {
    required,
    emailValidator,
    integerValidator,
    regexValidator,
} from '@core/utils/validation'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import { splitFullName, formatTelNum } from '@/util/functions/index.js'

export default {
    name: 'BasicProfile',
    props: {
        isBioDialogOpen: {
            type: Boolean,
            required: true,

            // default: true
        },

    },
    data() {
        return {
            nextState: false,
            refreshKey: 0,
            visitId: null,
            dob: null,
            saveSuccess: false,
            invalidForm: false,
            address_components: [],
            autoAddress: '',
            userDataLocal: {
                // firstName: '',
                // lastName: '',
                // dateofBirth: '',
                // healthCardNumber: '',
                // phoneNumber: '',
                // email: '',
                // formattedAddress: '',
                // unit: '',
                // street_number: '',
                // route: '',
                // locality: '',
                // province: '',
                // postal_code: '',
                // country: '',
                // lastUpdated: '',
            },
            icons: {
                mdiCheckboxMarkedCircleOutline,
                mdiChevronRight
            },
        }
    },
    setup() {
        const requiredField = ref('')
        const visitStore = useVisitStore()
        const timeStamp = new Date(Date.now()).toISOString()
        const form = ref(null)
        const validate = () => {
            form.value.validate()
        }

        return {
            requiredField,
            emailValidator,
            integerValidator,
            regexValidator,
            form,
            validate,
            required,
            visitStore,
            timeStamp
        }
    },
    computed: {
        patient() {
            return splitFullName(this.visitStore.patientData.name)
        },
        patientData() {
            return this.visitStore.patientData
        },
    },
    watch: {
        isBioDialogOpen: {
            handler(newVal) {
                if (newVal) {
                    this.savedState()
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.visitId = this.visitStore.visitId
        this.dob = this.visitStore.dob
        this.checkAddress()
        this.savedState()
    },
    methods: {
        next() {
            this.$emit('next', 2)
        },
        closeDialog() {
            this.checkAddress()

            // this.$emit('update:is-bio-dialog-open', false)
        },
        checkAddress() {
            const loader = new Loader({
                // apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
                apiKey: 'AIzaSyD5e-FUz44ioYXmOhU48iKJZ7BXXYNzPMk',
                version: 'weekly',
                libraries: ['places'],
            })
            if (this.isBioDialogOpen) {
                loader.load().then(() => {
                    const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocompleteNew'), {
                        fields: ['address_components', 'formatted_address', 'adr_address', 'place_id', 'url'],
                    })
                    autocomplete.setComponentRestrictions({ country: 'ca' })
                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace()

                        // this.userDataLocal.address = place.formatted_address

                        this.userDataLocal.country = place.address_components.find(item => item.types.includes('country'))?.long_name || ''
                        this.userDataLocal.street_number = place.address_components.find(item => item.types.includes('street_number'))?.long_name || ''
                        this.userDataLocal.route = place.address_components.find(item => item.types.includes('route'))?.long_name || ''
                        this.userDataLocal.locality = place.address_components.find(item => item.types.includes('locality'))?.long_name || ''
                        this.userDataLocal.province = place.address_components.find(item => item.types.includes('administrative_area_level_1'),)?.long_name || ''
                        this.userDataLocal.postal_code = place.address_components.find(item => item.types.includes('postal_code'))?.long_name || ''
                        this.userDataLocal.unit = place.address_components.find(item => item.types.includes('subpremise'))?.long_name || ''

                        const unitDash = this.userDataLocal.unit ? `${this.userDataLocal.unit} -` : ''
                        this.userDataLocal.formattedAddress = `${unitDash} ${this.userDataLocal.street_number} ${this.userDataLocal.route} ${this.userDataLocal.locality}, ${this.userDataLocal.province} ${this.userDataLocal.postal_code} ${this.userDataLocal.country}`
                        this.userDataLocal.formattedAddress = this.userDataLocal.formattedAddress.trim()
                        this.userDataLocal.lastUpdated = this.timeStamp
                        this.refreshKey += 1

                        // this.userDataLocal.address = `${this.userDataLocal.street_number} ${this.userDataLocal.route} ${this.userDataLocal.locality} ${this.userDataLocal.province} ${this.userDataLocal.postal_code}`
                    })
                })
            }
        },
        generatedFormattedAddress() {
            const unitDash = this.userDataLocal.unit ? `${this.userDataLocal.unit} -` : ''
            this.userDataLocal.formattedAddress = `${unitDash} ${this.userDataLocal.street_number} ${this.userDataLocal.route} ${this.userDataLocal.locality}, ${this.userDataLocal.province} ${this.userDataLocal.postal_code} ${this.userDataLocal.country}`
            this.userDataLocal.formattedAddress = this.userDataLocal.formattedAddress.trim()
            this.refreshKey += 1
        },
        async onSubmit() {
            this.generatedFormattedAddress()
            const data = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                id: this.visitStore.patientData.id,
                drugPlan: this.userDataLocal.fullName,
                detailedAddress: this.userDataLocal,
                email: this.userDataLocal.email,
                phoneNumber: formatTelNum(this.userDataLocal.phoneNumber),
                firstName: this.userDataLocal.firstName,
                lastName: this.userDataLocal.lastName,
            }

            const validForm = this.$refs.form.validate()
            console.log('🚀 ~ file: basicProfile.vue ~ line 461 ~ onSubmit ~ validForm', validForm)

            if (validForm) {
                const response = await visitService.updatePatientProfile(data)
                if (response.status === 'success') {
                    this.saveSuccess = true
                    this.$emit('next', 2)
                    setTimeout(() => {
                        this.saveSuccess = false
                    }, 2500)
                }

                await this.visitStore.setVisit({ id: this.visitId, dob: this.dob })

                this.nextState = true
            } else if (!validForm) {
                this.invalidForm = true
                setTimeout(() => {
                    this.invalidForm = false
                }, 2500)
            }

            // this.$emit('update:is-bio-dialog-open', false)
        },
        savedState() {
            this.userDataLocal = {
                firstName: Object.prototype.hasOwnProperty.call(this.patientData, 'firstName') ? this.patientData.firstName : this.patient.FirstName,
                lastName: Object.prototype.hasOwnProperty.call(this.patientData, 'lastName') ? this.patientData.lastName : this.patient.LastName,
                dateofBirth: Object.prototype.hasOwnProperty.call(this.patientData, 'dateofBirth') ? this.patientData.dateofBirth : '',
                healthCardNumber: Object.prototype.hasOwnProperty.call(this.patientData, 'healthCardNumber') ? this.patientData.healthCardNumber : '',
                phoneNumber: Object.prototype.hasOwnProperty.call(this.patientData, 'phoneNumber') ? this.patientData.phoneNumber : '',
                email: Object.prototype.hasOwnProperty.call(this.patientData, 'email') ? this.patientData.email : '',
                country: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'country') ? this.patientData.detailedAddress.country : '',
                street_number: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'street_number') ? this.patientData.detailedAddress.street_number : '',
                route: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'route') ? this.patientData.detailedAddress.route : '',
                locality: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'locality') ? this.patientData.detailedAddress.locality : '',
                province: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'province') ? this.patientData.detailedAddress.province : '',
                postal_code: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'postal_code') ? this.patientData.detailedAddress.postal_code : '',
                unit: Object.prototype.hasOwnProperty.call(this.patientData?.detailedAddress || this.patientData, 'unit') ? this.patientData.detailedAddress.unit : '',
            }
        }
    },
}
</script>
