var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"user-edit-info ",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"mt-5"},[_c('v-form',{ref:"imageUploadForm",staticClass:"multi-col-validation"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"tw-text-gray-600 tw-text-base"},[_vm._v(" Do you have private insurance coverage? ")]),_c('v-radio-group',{attrs:{"rules":_vm.radioRules},model:{value:(_vm.insuranceStatus),callback:function ($$v) {_vm.insuranceStatus=$$v},expression:"insuranceStatus"}},_vm._l((_vm.boolRadioOptions),function(option){return _c('v-radio',{key:option.value,attrs:{"label":option.text,"value":option.value}})}),1)],1),(_vm.insuranceStatus === 1)?_c('div',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"tw-text-gray-600 tw-text-base"},[_vm._v(" Please upload images of your insurance card(s) or documents. ")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-file-input',{ref:"file",attrs:{"color":"success","loading":_vm.loading,"multiple":"","placeholder":"Select your files","prepend-icon":_vm.icons.mdiCameraOutline,"hide-details":"","outlined":"","show-size":1000,"dense":"","counter":""},on:{"change":function($event){return _vm.submitFile()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"success","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.images.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,4236564937),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.saveSuccess)?_c('v-alert',{attrs:{"type":"success"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.fileUploadMessage)}})]):_vm._e(),(_vm.invalidForm)?_c('v-alert',{staticClass:"mb-0",attrs:{"color":"error","dark":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-switch',{attrs:{"label":("" + (_vm.switchArray[_vm.showTable])),"true-value":"true","false-value":"false","hide-details":""},model:{value:(_vm.showTable),callback:function ($$v) {_vm.showTable=$$v},expression:"showTable"}})],1),(_vm.showTable === 'true')?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('p',[_vm._v("Current Uploads on file:")]),_c('v-data-table',{attrs:{"items":_vm.visitStore.patientData.upload,"headers":_vm.uploadHeaders,"sort-by":"body.timeCreated","sort-desc":""},scopedSlots:_vm._u([_vm._l((_vm.uploadHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
                  )),function(header){return {key:("item." + (header.value)),fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.dlPDF",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudDownload)+" ")])]}},{key:"item.thumbnailBase64",fn:function(ref){
                  var header = ref.header;
                  var value = ref.value;
return [(value === '')?_c('v-icon',{staticClass:"me-3",attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilePdfBox)+" ")]):_vm._e(),_c('v-img',{attrs:{"contain":"","max-height":"48","max-width":"96","src":header.formatter(value)}})]}},{key:"item.delete",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"error","medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{staticClass:"me-2",attrs:{"vue-tour-target":"previousButton","outlined":""},on:{"click":_vm.previous}},[_vm._v(" Previous ")])],1),_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}},[_vm._v(" Next ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }